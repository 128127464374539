/*

  Navigation

*/

.c-navigation {
    flex-direction: row;
    padding-bottom: 0;
}

.c-navigation__toggler {
    display: none;
}

.c-navigation__innerwrap {
    display: block;
}
