/*

  Header

*/

.c-header {
    text-align: center;
    position: relative;
}

.c-header__bar {
    @include carrschmidbar;
}

@media #{$medium-up} {
    .c-header {
        text-align: left;

        /** https://cssgradient.io/ **/
        background: rgb(255,255,255);
        background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(156,158,159,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(156,158,159,1) 100%);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(156,158,159,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#9c9e9f",GradientType=1);
    }
}
