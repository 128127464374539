/*

  Heading

*/

.o-heading {
  @include heading;
}

.o-heading--1 {
  @include heading-1;
}

.o-heading--2 {
  @include heading-2;
}

.o-heading--3 {
  @include heading-3;
}

.o-heading--4 {
  @include heading-4;
}
