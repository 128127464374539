/*

  Service

*/

.c-service {
    @include eq-height;
    margin-bottom: 4rem;

    h3 {
        @include eq-height;
        color: $black;
        margin-bottom: 1rem;

        &:hover {
            color: $black;
        }
    }
    image {
        margin-bottom: 1rem;
    }
}
