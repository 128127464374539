/*

  Sidebar

*/

.c-sidebar {
    text-align: center;
    padding-top: 50px;

}

.c-sidebar__container {
    padding-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }
}

.c-sidebar__image {
    max-width: 200px;
    padding: 20px;
}

@media #{$medium-up} {

    .c-sidebar {
        text-align: right;
        padding-top: 0;
    }

    .c-sidebar__image {
        padding: 10px 0;
        max-width: 120px;
        display: block;
        margin-right: 0;
        margin-left: auto;
    }
}

@media #{$large-up} {
    .c-sidebar__image {
        max-width: 180px;
    }
}
