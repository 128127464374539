/*

  Slider

*/

.tns-nav {
    text-align: center;

    button {
        background-color: grey;
        border-radius: 100%;
        outline: none;
        border: none;
        width: 12px;
        height: 12px;
        padding: 3px;
        margin: 5px;

        &.tns-nav-active {
            background-color: $primary;
        }
    }
}

.tns-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 200;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    pointer-events: none;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 100%;
        background-color: transparent;
        border: 1px solid rgba($white, 0.3);
        color: rgba($white, 0.3);
        font-size: 30px;
        line-height: 1;
        transition: $transition-base;
        padding: 0;
        pointer-events: all;

        &:first-child {
            padding-right: 3px;
        }
        &:last-child {
            padding-left: 3px;
        }
    }
}


.tns-outer {
    position: relative;

    img {
        width: 100%;
    }

    &:hover {
        .tns-controls button {
            border-color: rgba($white, 0.8);
            color: rgba($white, 0.8);
        }
    }
}
