/*

  Holiday

*/


table.holiday {
    margin-top: 0;

    td {
        padding: 8px 10px;
        margin: 0;
    }

    p {
        margin: 0;
    }
}
