/*

  Footer

*/

footer {
    background-color: lightgrey;
    margin-top: 50px;
    padding: 20px 0;

    p {
        margin: 0;
    }

    .icon--angle-up {
        font-size: 100px;
    }
}
