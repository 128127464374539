/*

  Settings

  are generally a collection of variables
  that do not generate CSS, but are applied to classes.

*/
@import "variables/variables";
@import "bootstrap/bootstrap";
