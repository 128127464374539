/*

    Table

*/

table {
  width:100%;
}

td, th {
  border-right: 1px solid lightgrey;
  padding: 2px 10px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    border-right: none;
  }
}