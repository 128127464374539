/*

  Container

*/

.o-container {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: map-get($container-max-widths, "sm");
}

@each $color, $value in $theme-colors {
  .o-container--#{$color} {
    background-color: $value;
  }
}

.o-container__offset {
  position: relative;
  padding-left: rem($container-offset);
  padding-right: rem($container-offset);
}

@media #{$medium-up} {
  @import "container_medium-up";
}

@media #{$large-up} {
  @import "container_large-up";
}

@media #{$extra-large-up} {
  @import "container_extra-large-up";
}
