/*

  Vendors

  are third-party styles,
  that produce real CSS.

*/
@import 'bootstrap/bootstrap';
@import "tiny-slider/tiny-slider";
@import "tntsearch/tntsearch";
