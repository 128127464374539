/*

  Mixins

*/

// Headings

@mixin heading {
  font-weight: 700;
  color: inherit;
  word-wrap: break-word;
}

@mixin heading-1 {
  @include heading;
  font-size: rem(30px);
  line-height: rem(35px);
  padding: 0 0 rem(30px) 0;
}

@mixin heading-2 {
  @include heading;
  font-size: rem(30px);
  line-height: rem(35px);
  padding: 0 0 rem(10px) 0;
}

@mixin heading-3 {
  @include heading;
  font-size: rem(22px);
  line-height: rem(25px);
  padding: 0 0 rem(5px) 0;
}

@mixin heading-4 {
  @include heading;
  font-size: rem(18px);
  line-height: rem(28px);
  margin: 0 0 rem(30px) 0;
}

// Paragraph

@mixin paragraph {
  font-weight: 400;
  font-size: rem(18px);
  line-height: rem(28px);
  margin: 0 0 rem(30px) 0;
}

@mixin paragraph-small {
  @include paragraph;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(20px);
}

@mixin paragraph-lead {
  @include paragraph;
  font-weight: 400;
  font-size: rem(20px);
  line-height: rem(30px);
}

@mixin carrschmidbar {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: $primary;
}

@mixin eq-height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 1px;
}
