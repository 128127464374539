/*

  Body

*/

body {
  @include paragraph;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}
