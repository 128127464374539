/*

  Print

*/

@media print {

  html, body {
    width: 210mm;
    height: 297mm;
  }

  .o-container {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .row {
    display: block;
    flex: none;

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        flex: none;
        float: left;
        width: percentage($i / $grid-columns);
      }
    }
  }

  @for $i from 1 through $grid-columns {
    .col-print-#{$i} {
      flex: none;
      float: left;
      width: percentage($i / $grid-columns);
    }
  }

}
