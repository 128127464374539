/*

  Navigation

*/

.c-navigation__wrapper {
    background-color: lightgrey;
    position: relative;
}

.c-navigation__toggler {
    width: 50px;
    height: 30px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    outline: none !important;

    span {
        width: 30px;
        height: 2px;
        background-color: $black;
        transition: $transition-base;
        transform-origin: left;
    }

    &.active {
        span {
            width: 20px;
            &:first-child {
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
}

.c-navigation__innerwrap {
    display: none;
}

.c-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding-bottom: 30px;
}

.c-navigation--subnavigation {
    display: none;
}

.c-navigation__item {
}

.c-navigation__link {
    display: block;
    padding: 10px 15px;
    font-weight: 300;
    text-decoration: none;

    &:hover {
        background-color: dimgray;
    }
}

.c-navigation__bar {
    @include carrschmidbar;
}

@media #{$medium-up} {
    @import "navigation_medium-up";
}
