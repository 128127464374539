/*

  Image

*/

img {
  height: auto;
  max-width: 100%;
}
