/*

  Typography

*/
@import "typography__webfont";

// Headings

h1, h2, h3, h4 {
  @include heading;
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4, h5{
  @include heading-4;
}

h5 {
    font-weight: 400;
    padding-top: 20px;
    margin: 0;
}


// Paragraph

p {
  @include paragraph;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

small {
  @include paragraph-small;
}

// Link

a {
  transition: all $transition;
}

.content, footer {
    a {
        color: $primary;
        &:hover {
            color: darken($primary, 20);
        }
    }
}

.no-margin {
    margin: 0;
}

.hidden {
    display: none;
}
