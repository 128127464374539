/*

  Components (namespace c-)

  are a small feature that makes up a part of the website.

*/

@import "header/header";
@import "navigation/navigation";
@import "content/content";
@import "sidebar/sidebar";
@import "service/service";
@import "socialfeed/socialfeed";
@import "totop/totop";
@import "holiday/holiday";
