/*

  Scope (namespace s-)

  Be warned;
  Only use the scope if it’s absolutely necessary.
  The purpose of the scope is to give us the highest specificity so
  we can overwrite any styles for a specific purpose.

*/
