@font-face {
	font-family: "icons";
	font-display: block;
	src: url("/user/themes/template/fonts/icons.woff2?97a40e0efebb3a02cd0f4832d976a147") format("woff2"),
url("/user/themes/template/fonts/icons.ttf?97a40e0efebb3a02cd0f4832d976a147") format("truetype");
}

.icon {
	font-family: icons;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	vertical-align: middle;
}

.icon--angle-up:before {
	content: "\f101";
}
.icon--facebook:before {
	content: "\f102";
}
.icon--next:before {
	content: "\f103";
}
.icon--prev:before {
	content: "\f104";
}
