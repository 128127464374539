/*

  Modular

*/

.o-modular {
    padding-bottom: 30px;
}

.o-modular--urgent {
    border: 1px solid grey;
    background-color: rgba($primary, 0.2);
    text-align: center;
    padding: 40px 15px 0;
    margin-bottom: 40px;
}