/*

  Paragraph

*/

.o-paragraph {
  @include paragraph;
}

.o-paragraph--small {
  @include paragraph-small;
}

.o-paragraph--lead {
  @include paragraph-lead;
}
