/*

  Image

*/

.o-image--fluid {
  width: 100%;
}

.o-image--lazy {
  opacity: 0;

  &:not(.initial) {
    transition: opacity $transition;
  }

  &.initial,
  &.loaded,
  &.error {
    opacity: 1;
  }

  &:not([src]) {
    visibility: hidden;
  }
}
