/*

  HTML

*/

html {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
