/*

  To Top

*/

.totop {
    color: $primary;
    cursor: pointer;
}
