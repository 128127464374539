/*

  Media Queries

*/

$screen: "only screen";

$small-up: "#{$screen} and (min-width:#{breakpoint-min("sm", $grid-breakpoints)})";
$medium-up: "#{$screen} and (min-width:#{breakpoint-min("md", $grid-breakpoints)})";
$large-up: "#{$screen} and (min-width:#{breakpoint-min("lg", $grid-breakpoints)})";
$extra-large-up: "#{$screen} and (min-width:#{breakpoint-min("xl", $grid-breakpoints)})";

$extra-small-down: "#{$screen} and (max-width:#{breakpoint-max("xs", $grid-breakpoints)})";
$small-down: "#{$screen} and (max-width:#{breakpoint-max("sm", $grid-breakpoints)})";
$medium-down: "#{$screen} and (max-width:#{breakpoint-max("md", $grid-breakpoints)})";
$large-down: "#{$screen} and (max-width:#{breakpoint-max("lg", $grid-breakpoints)})";

$extra-small-only: "#{$screen} and (max-width:#{breakpoint-max("xs", $grid-breakpoints)})";
$small-only: "#{$screen} and (min-width:#{breakpoint-min("sm", $grid-breakpoints)}) and (max-width:#{breakpoint-max("sm", $grid-breakpoints)})";
$medium-only: "#{$screen} and (min-width:#{breakpoint-min("md", $grid-breakpoints)}) and (max-width:#{breakpoint-max("md", $grid-breakpoints)})";
$large-only: "#{$screen} and (min-width:#{breakpoint-min("lg", $grid-breakpoints)}) and (max-width:#{breakpoint-max("lg", $grid-breakpoints)})";
$extra-large-only: "#{$screen} and (min-width:#{breakpoint-min("xl", $grid-breakpoints)})";
