/*

  Functions

*/

@function rem($size) {
  $rem: $size / 10px;
  @return #{$rem}rem;
}
