/*

  Variables

*/

// Colors

$white: #FFFFFF;
$black: #000000;

$primary: #FF9901;

$colors: (
  "white": $white,
  "black": $black
);

$theme-colors: ();
$theme-colors: map-merge($colors, $theme-colors);

// Body

$body-bg: $white;
$body-color: $black;

// Links

$link-color: inherit;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: none;

// Grid Breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 580px,
  md: 770px,
  lg: 990px,
  xl: 1460px
);

// Grid Containers

$container-max-widths: (
  sm: map-get($grid-breakpoints, sm),
  md: 710px,
  lg: 930px,
  xl: 1400px
);

// Components

$border-width: 1px;
$border-color: $black;
$border-radius: 2px;

$box-shadow: 0 0 4px 0 rgba($black, 0.2);

// Typography

$font-size-base: 1.8rem;
$font-family-sans-serif: "Open Sans", "Arial", sans-serif;

// Transitions

$transition: 300ms ease;

// Container

$container-offset: 15px;
$container-offset-xl: 30px;
