/*

  Objects (namespace o-)

  are used for design patterns, such as layouts, where items are
  being arranged rather than decorated.
  Object classes are used across all page.
  So called "cosmetic free design patterns" and are used multiple times.

*/
@import "container/container";
@import "frame/frame";
@import "heading/heading";
@import "icon/icon";
@import "image/image";
@import "paragraph/paragraph";
@import "modular/modular";
@import "slider/slider";
