/*

  Typography: Webfonts

*/

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/user/themes/template/webfonts/OpenSans-Light.woff2) format('woff2'), url(/user/themes/template/webfonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/user/themes/template/webfonts/OpenSans-Regular.woff2) format('woff2'), url(/user/themes/template/webfonts/OpenSans-Regular.ttf) format('truetype');
}

/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/user/themes/template/webfonts/OpenSans-Bold.woff2) format('woff2'), url(/user/themes/template/webfonts/OpenSans-Bold.ttf) format('truetype');
}
