.o-social-feed {
    background: none;
    margin-left: -5px;
    margin-right: -5px;
}

.o-social-feed__item {
    width: 100%;
    padding: 10px 5px;
}

.o-social-feed__card {
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    border-top: 1px solid #ECECEC;
}

.o-social-feed__text {
    padding: 10px 5px;
    text-align: center;
}






.o-social-feed__card-bottom {
    padding: 10px 5px;
    background-color: #ececec;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.o-social-feed__icon-facebook {
    background-color: lightgray;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 15px;

    i {
        color: #3B5998;
        padding-right: 1px;
    }
}


@media #{$small-up} {
    .o-social-feed__item {
        width: 50%;
    }
}

@media #{$medium-up} {
    .o-social-feed__item {
        width: 50%;
    }
}

@media #{$large-up} {
    .o-social-feed__item {
        width: 50%;
    }
}

@media #{$extra-large-up} {
    .o-social-feed__item {
        width: 33%;
    }
}
