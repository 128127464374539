/*

  Iframe

*/

iframe {
    width: 100%;
    height: 250px;

    @media #{$small-up} {
        height: 350px;
    }

    @media #{$medium-up} {
        height: 350px;
    }

    @media #{$large-up} {
        height: 500px;
    }

    @media #{$extra-large-up} {
        height: 650px;
    }
}
