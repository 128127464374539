/*

  Container: Extra Large Up

*/

.o-container {
  max-width: map-get($container-max-widths, "xl");
}

.o-container__offset {
  padding-left: rem($container-offset-xl);
  padding-right: rem($container-offset-xl);
}