/*

  Frame

*/

.o-frame {
  position: relative;
}

@each $color, $value in $theme-colors {
  .o-frame--#{$color} {
    background-color: $value;

    @if lightness($value) <= 60% {
      color: $white;
    }
  }
}