/*

    List

*/

ul, ol {
  list-style: inside;
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}